import { RiExternalLinkLine, RiMailOpenLine, RiPhoneLine, RiContactsBookLine, RiLinkedinBoxLine, RiWhatsappLine, RiInstagramLine, RiGithubLine, RiTwitterLine } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";

import vCardsJS from 'vcards-js';
import { saveAs } from 'file-saver';

function App() {

  const handleSaveCard = () => {
    //create a new vCard
    var vCard = vCardsJS();

    //set properties
    vCard.firstName = 'Samay';
    vCard.lastName = 'Bhattacharyya';
    // vCard.photo.embedFromString(base64.LOGO, 'image/png');
    // vCard.logo.embedFromString(base64.LOGOLOGO, 'image/png');
    vCard.photo.attachFromUrl('https://ik.imagekit.io/wig4h1dj7ks/vcard-samay_EtPbalW_m.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671296069695', 'JPG');
    vCard.logo.attachFromUrl('https://ik.imagekit.io/wig4h1dj7ks/conferx-favicon_oub6whKzI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1671297946365', 'PNG');
    vCard.workPhone = '+918100151008';
    vCard.title = 'Website and Mobile Application Developer';
    vCard.workEmail = ['contact@samay.dev', 'ceo@wrettle.com'];
    vCard.url = 'https://samay.dev';
    vCard.note = 'For all your website, mobile or software development needs';

    vCard.homeAddress.city = 'West Bengal';
    vCard.homeAddress.countryRegion = 'India';

    vCard.socialUrls['linkedIn'] = 'Samay Bhattacharyya https://www.linkedin.com/in/samay-bhattacharyya/';
    vCard.socialUrls['custom'] = 'Open Twitter https://twitter.com/samay_dev'

    vCard.version = '3.0';

    //save to file
    const blob = new Blob([vCard.getFormattedString().replaceAll("CHARSET=UTF-8;","")], { type: "text/vcard" });
    saveAs(blob, `samay.vcf`);
  }

  return (
    // bg-[#FC7A69]
    <section className="h-screen w-screen">
      <div className="max-w-xl mx-auto flex flex-col justify-center px-6">
        <img src="./images/logo.png" alt="" className="w-14 mx-auto mt-4" />
        <div className="w-full relative -mt-6">
          <div className="absolute left-0 right-0 top-0 bottom-0 flex flex-col justify-end z-10">
            <h1 className=" text-4xl font-bold text-[#FC7A69]">Hello There</h1>
            <h3 className="text-xl font-bold text-[#FC7A69]">Thanks for checking me out!</h3>
          </div>
          <img src="./images/samay.png" alt="" className="float-right w-2/3 opacity-80 pb-6" />
        </div>
        <div className="mt-6 relative z-20 pb-6">
          <a href="https://samay.dev?ref=card" target="_blank" rel="noreferrer" className="flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            My website
            <RiExternalLinkLine className="fill-current" size={20} />
          </a>
          <a href="mailto:contact@samay.dev" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            Write an email
            <RiMailOpenLine className="fill-current" size={20} />
          </a>
          <a href="tel:+918100151008" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            Place a call
            <RiPhoneLine className="fill-current" size={20} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://ik.imagekit.io/wig4h1dj7ks/Samay-CV__1__N-BA_FfeL.pdf" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            Download my CV
            <IoDocumentTextOutline className="fill-current" size={20} />
          </a>
          <button onClick={handleSaveCard} className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            Save my card
            <RiContactsBookLine className="fill-current" size={20} />
          </button>
          <h3 className="mt-4 text-xl font-bold text-[#FC7A69]">Socials</h3>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/samay-bhattacharyya/" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            LinkedIn
            <RiLinkedinBoxLine className="fill-current" size={20} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://wa.me/918100151008" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            Whatsapp
            <RiWhatsappLine className="fill-current" size={20} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://instagram.com/mainactivity.class" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            Instagram
            <RiInstagramLine className="fill-current" size={20} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://github.com/neilkerman" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            GitHub
            <RiGithubLine className="fill-current" size={20} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/samay_dev" className="mt-4 flex justify-between items-center w-full rounded-2xl bg-[#FC7A69] px-4 text-center py-3 font-semibold text-white shadow-md shadow-[#fc7a696f] hover:shadow-[#fc7a69ac] transition-all duration-300">
            Twitter
            <RiTwitterLine className="fill-current" size={20} />
          </a>
        </div>
      </div>
    </section>
  );
}

export default App;
